import * as React from 'react';
import { Admin, Resource, ListGuesser, defaultTheme } from 'react-admin';
import Layout from './components/Layout';

import employees from './pages/employees';
import companies from './pages/companies';
import { Dashboard } from './pages/dashboard/Dashboard';

import {
    FirebaseDataProvider,
    FirebaseAuthProvider,
    RAFirebaseOptions
  } from "react-admin-firebase";

const config = require("./FIREBASE_CONFIG.js").firebaseConfig;

const options: RAFirebaseOptions = {
  logging: true,
  rootRef: "human_resource/resource_management",
  watch: ["employees", "companies"]
};
const dataProvider = FirebaseDataProvider(config, options);
const authProvider = FirebaseAuthProvider(config, options);

const App = () => (
    <Admin
        dataProvider={dataProvider}
        authProvider={authProvider}
        layout={Layout}
        dashboard={Dashboard}
        theme={{
            ...defaultTheme,
            palette: {
                background: {
                    default: '#fafafb',
                },
            },
        }}
    >
        <Resource name="employees" {...employees} />
        <Resource name="companies" {...companies} />
        <Resource name="contactNotes" />
        <Resource name="tasks" list={ListGuesser} />
        <Resource name="tags" list={ListGuesser} />
    </Admin>
);

export default App;
