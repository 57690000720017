import * as React from 'react';
import {
    TextInput,
    ReferenceInput,
    AutocompleteInput,
    BooleanInput,
    SelectInput
} from 'react-admin';
import { Divider, Box } from '@mui/material';

export const ContactInputs = () => (
    <Box flex="1" mt={-1}>
        <Box display="flex" width={430}>
            <TextInput source="first_name" fullWidth defaultValue="" />
            <Spacer />
            <TextInput source="last_name" fullWidth defaultValue="" />
        </Box>
        <Box display="flex" width={430}>
            <TextInput source="title" fullWidth defaultValue="" />
            <Spacer />
            <ReferenceInput source="company_id" reference="companies">
                <AutocompleteInput optionText="name" fullWidth defaultValue=""/>
            </ReferenceInput>
        </Box>
        <Divider />
        <Box mt={2} width={430}>
            <TextInput source="email" fullWidth defaultValue="" />
        </Box>
        <Box display="flex" width={430}>
            <TextInput source="phone_number1" fullWidth defaultValue="" />
            <Spacer />
            <TextInput source="phone_number2" fullWidth defaultValue="" />
        </Box>
        <Divider />
        <Box mt={2} width={430}>
            <SelectInput
                source="gender"
                fullWidth
                choices={[
                    { id: 'male', name: 'Male' },
                    { id: 'female', name: 'Female' }
                ]}
            />
            <TextInput source="background" multiline fullWidth defaultValue="" />
            <TextInput source="avatar" fullWidth defaultValue="" />
            <BooleanInput source="has_newsletter" defaultValue="" />
        </Box>
    </Box>
);

const Spacer = () => <Box width={20} component="span" />;
