import * as React from 'react';
import { Grid } from '@mui/material';

import { HotContacts } from './HotContacts';
import { LatestNotes } from './LatestNotes';

export const Dashboard = () => (
    <Grid container spacing={2} mt={1}>
        <Grid item xs={12} md={6}>
            {/* <LatestNotes /> */}
        </Grid>
        <Grid item xs={12} md={3}>
            {/* <HotContacts /> */}
        </Grid>
    </Grid>
);
