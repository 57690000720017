// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
export const firebaseConfig = {
  apiKey: "AIzaSyDDv8hA07t-juBgCwYEERO7WYI31q3pIUA",
  authDomain: "aether--hr-tool.firebaseapp.com",
  projectId: "aether--hr-tool",
  storageBucket: "aether--hr-tool.appspot.com",
  messagingSenderId: "114478603643",
  appId: "1:114478603643:web:a89c802341e6ddb6a5f1de",
  measurementId: "G-YJPLL47L3M"
};