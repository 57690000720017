import * as React from 'react';

import { ReferenceInput, TextInput, SelectInput, required } from 'react-admin';
import { Divider, Stack, Grid } from '@mui/material';

import { sectors } from './sectors';
import { sizes } from './sizes';

export const CompanyForm = () => (
    <>
        <TextInput source="name" validate={required()} fullWidth defaultValue="" />
        <Stack direction="row">
            <SelectInput
                source="sector"
                choices={sectors}
                sx={{ width: 200 }}
            />
            <SelectInput
                source="size"
                choices={sizes}
                sx={{ ml: 2, width: 200 }}
            />
        </Stack>
        <Divider sx={{ mb: 2, width: '100%' }} />

        <TextInput source="address" fullWidth helperText={false} defaultValue="" />
        <Grid container spacing={2}>
            <Grid item xs={12} sm={4}>
                <TextInput fullWidth source="city" defaultValue="" />
            </Grid>
            <Grid item xs={12} sm={4}>
                <TextInput fullWidth source="zipcode" defaultValue="" />
            </Grid>
            <Grid item xs={12} sm={4}>
                <TextInput fullWidth source="stateAbbr" defaultValue="" />
            </Grid>
        </Grid>
        <Divider sx={{ mb: 2, width: '100%' }} />

        <TextInput source="website" fullWidth helperText={false} defaultValue="" />
        <TextInput source="linkedIn" fullWidth helperText={false} defaultValue="" />
        <TextInput source="logo" fullWidth defaultValue="" />
    </>
);
