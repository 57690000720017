import { ContactShow } from './EmployeeShow';
import { ContactList } from './EmployeeList';
import { ContactEdit } from './EmployeeEdit';
import { ContactCreate } from './EmployeeCreate';

export default {
    list: ContactList,
    show: ContactShow,
    edit: ContactEdit,
    create: ContactCreate,
};
